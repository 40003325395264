import React from 'react'
import Api from './Components/Api'
import './Components/App.css'

function App() {
    return (
      <div className='container'>
        <>
        <Api/></>
      </div>
    )
  }
  export default App